import React, { useEffect, useRef } from 'react';
import * as deepar from 'deepar';
import userInteraction from 'services/user-interaction';
import { EConsumption } from 'enum/user';
import { EScene } from 'enum/scene';
import googleTagManager from 'services/google-tag-manager';
import sceneInteraction from 'services/scene-interaction';
import './DeepARMirror.scss';

/**
 * Overwrite the window object to add DeepAR for typescript
 */
declare global {
  interface Window {
    DeepAR: any;
  }
}

interface IProps {
  selectedSkinIndex?: number;
  isUnmount?: boolean;
}

let deepARElement: any;

const DeepARMirror = ({ selectedSkinIndex = 0, isUnmount = false }: IProps): React.ReactElement => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (isUnmount) {
      deepARElement?.stopVideo();
      deepARElement?.shutdown();
    }
  }, [isUnmount]);

  const goToBarScreen = (): void => {
    googleTagManager.onCancelButtonClick(EScene.MIRROR, null);
    sceneInteraction.goToBarScreen(true);
  };

  useEffect(() => {
    (async () => {
      const deepARObject = {
        licenseKey: process.env.REACT_APP_DEEP_AR_IO_KEY || '',
        canvas: canvasRef.current as HTMLCanvasElement,
        effect: '',
      };
      if (userInteraction.getUserConsumption() === EConsumption.VAPER) {
        deepARObject.effect = `./deepar/effects/rat-deformation-v2.bin`;
      } else {
        deepARObject.effect = `./deepar/effects/skintone-${selectedSkinIndex + 1}.bin`;
      }

      deepar
        .initialize(deepARObject)
        .then((deepAR) => {
          deepARElement = deepAR;
        })
        .catch(() => {
          goToBarScreen();
        });
    })();
  }, []);

  return <canvas className="deepAR-mirror" ref={canvasRef} id="deepar-canvas" width="400" height="800" />;
};
export default DeepARMirror;
