import gsap from 'gsap';
import { debounce } from 'lodash';
import googleTagManager from './google-tag-manager';
import {
  changeBarScene,
  changeBarVapeScene,
  changeMusic,
  changeMusicState,
  changeScene,
  completeBarScene,
  completeProfile,
} from 'action/scene-action';
import { EBarScene, EBarSceneVape, EMusicScene, EScene } from 'enum/scene';
import { EConsumption } from 'enum/user';
import { IReduxState } from 'interface/i-redux-state';
import store from 'store/Store';
import userInteraction from 'services/user-interaction';

class SceneInteraction {
  private currentScale: number;

  private firstTimeMoving: boolean;

  private stopRepeat: boolean;

  constructor() {
    this.goToNextScreen = this.goToNextScreen.bind(this);
    this.goToPreviousScreen = this.goToPreviousScreen.bind(this);
    this.goToNextBarScreen = this.goToNextBarScreen.bind(this);
    this.goToPreviousBarScreen = this.goToPreviousBarScreen.bind(this);
    this.getBarScene = this.getBarScene.bind(this);

    this.currentScale = 2;
    this.firstTimeMoving = true;
    this.stopRepeat = false;

    window.addEventListener('resize', this.onResize.bind(this));
  }

  onResize(): void {
    const state: IReduxState = store.getState();
    const barScene: EScene = this.getBarScene();
    // const sceneList: Array<string> = Object.keys(state.consumption === EConsumption.VAPER ? EBarSceneVape : EBarScene);
    if (!this.stopRepeat) {
      if (barScene && state.currentScene === EScene.INSIDE_BAR) {
        this.animateToScene(barScene, 0);
      }
      setTimeout((): void => {
        this.stopRepeat = true;
      }, 1000);
    }
  }

  /**
   * storeDispatch
   * Create a Redux Dispatch to the main store with a debounce
   * Use only inside this service
   * @param action: any - The action you would normally send to the store via a dispatch
   */
  public storeDispatch(action: any): void {
    const debounceFunc: () => void = debounce(() => {
      store.dispatch(action);
    }, 250);
    debounceFunc();
  }

  /**
   * goToNextScreen
   * Change the state of the `currentScreen` variable from the redux store
   * Display the next screen
   */
  goToNextScreen(): void {
    const state: IReduxState = store.getState();
    const sceneList: Array<string> = Object.keys(EScene);
    const currentIndex: number = sceneList.indexOf(state.currentScene);
    if (sceneList.length && sceneList.length > currentIndex) {
      const nextScene: string = sceneList[currentIndex + 1];
      switch (nextScene) {
        case EScene.INSIDE_BAR:
          this.goToMusic(EMusicScene.INSIDE);
          break;
        case EScene.COCKTAIL:
        case EScene.FREEZER:
        case EScene.MIRROR:
          this.goToMusic(EMusicScene.ACTIVITY);
          break;
        case EScene.QUIZZ:
          this.goToMusic(EMusicScene.QUIZZ);
          break;
        default:
          break;
      }
      this.storeDispatch(changeScene(nextScene as EScene));
    }
  }

  /**
   * goToPreviousScreen
   * Change the state of the `currentScreen` variable from the redux store
   * Display the previous screen
   */
  goToPreviousScreen(): void {
    const state: IReduxState = store.getState();
    const sceneList: Array<string> = Object.keys(EScene);
    const currentIndex: number = sceneList.indexOf(state.currentScene);
    if (sceneList.length && currentIndex > 0) {
      const nextScene: string = sceneList[currentIndex - 1];
      switch (nextScene) {
        case EScene.USER_REGISTRATION:
          this.goToMusic(EMusicScene.OUTSIDE);
          break;
        case EScene.INSIDE_BAR:
          this.goToMusic(EMusicScene.INSIDE);
          break;
        case EScene.COCKTAIL:
        case EScene.FREEZER:
        case EScene.MIRROR:
          this.goToMusic(EMusicScene.ACTIVITY);
          break;
        case EScene.QUIZZ:
          this.goToMusic(EMusicScene.QUIZZ);
          break;
        default:
          break;
      }
      this.storeDispatch(changeScene(nextScene as EScene));
    }
  }

  /**
   * goToNextScreen
   * Change the state of the `currentScreen` variable of the redux store
   * @param screenName
   */
  goToScreen(screenName: EScene | EBarScene): void {
    switch (screenName) {
      case EScene.COCKTAIL:
      case EScene.FREEZER:
      case EScene.MIRROR:
        this.goToMusic(EMusicScene.ACTIVITY);
        break;
      case EScene.QUIZZ:
        this.goToMusic(EMusicScene.QUIZZ);
        break;
      default:
        break;
    }
    this.storeDispatch(changeScene(screenName as EScene));
  }

  /**
   * goToBarScreen
   * Change the state of the `currentScreen` variable to always go back to the bar
   * Display the bar
   * @param goToNextBarScreen: boolean - Change the redux state to go to the next bar screen (for animation purpose)
   */

  goToBarScreen(goToNextBarScreen: boolean = false) {
    const state: IReduxState = store.getState();
    this.storeDispatch(completeBarScene(state.currentScene));
    if (state.currentScene === EScene.FREEZER_COMPLETE) {
      this.storeDispatch(completeBarScene(EScene.FREEZER));
    }
    this.storeDispatch(changeScene(EScene.INSIDE_BAR));
    this.goToMusic(EMusicScene.INSIDE);
    if (goToNextBarScreen) {
      setTimeout(() => {
        this.animateToCurrentScene(true);
      }, 250);
    } else {
      setTimeout(() => {
        this.animateToCurrentScene(false);
      }, 250);
    }
  }

  /**
   * goToNextBarScreen
   * Change the state of the `currentBarScreen` variable from the redux store
   * Display the next screen
   */
  goToNextBarScreen(): void {
    const state: IReduxState = store.getState();

    if (state.consumption === EConsumption.VAPER) {
      this.goToNextVapeSceneNotComplete();
    } else {
      this.goToNextSmokeSceneNotComplete();
    }
  }

  /**
   * goToPreviousBarScreen
   * Change the state of the `currentBarScreen` variable from the redux store
   * Display the previous screen
   */
  goToPreviousBarScreen(): void {
    const state: IReduxState = store.getState();

    if (state.consumption === EConsumption.VAPER) {
      this.goToPreviousVapeSceneNotComplete();
    } else {
      this.goToPreviousSmokeSceneNotComplete();
    }
  }

  /**
   * goToNextVapeSceneNotComplete
   * Change the state of the `currentBarScreen` variable from the redux store
   * Display the next screen
   */
  goToNextVapeSceneNotComplete(): void {
    const barScene: EScene = this.getBarScene();
    const sceneVapeList: Array<string> = Object.keys(EBarSceneVape);
    const currentIndex: number = sceneVapeList.indexOf(barScene);
    this.storeDispatch(changeBarVapeScene(sceneVapeList[currentIndex + 1] as EBarSceneVape));
  }

  /**
   * goToPreviousVapeSceneNotComplete
   * Change the state of the `currentBarScreen` variable from the redux store
   * Display the previous screen
   */
  goToPreviousVapeSceneNotComplete(): void {
    const barScene: EScene = this.getBarScene();
    const sceneVapeList: Array<string> = Object.keys(EBarSceneVape);
    const currentIndex: number = sceneVapeList.indexOf(barScene);
    this.storeDispatch(changeBarVapeScene(sceneVapeList[currentIndex - 1] as EBarSceneVape));
  }

  /**
   * goToNextSmokeSceneNotComplete
   * Change the state of the `currentBarScreen` variable from the redux store
   * Display the previous screen
   */
  goToNextSmokeSceneNotComplete(): void {
    const barScene: EScene = this.getBarScene();
    const sceneList: Array<string> = Object.keys(EBarScene);
    const currentIndex: number = sceneList.indexOf(barScene);
    this.storeDispatch(changeBarScene(sceneList[currentIndex + 1] as EBarScene));
  }

  animateToPrevScene(): void {
    const state: IReduxState = store.getState();
    const barScene: EScene = this.getBarScene();
    const sceneList: Array<string> = Object.keys(state.consumption === EConsumption.VAPER ? EBarSceneVape : EBarScene);
    const currentIndex: number = sceneList.indexOf(barScene);
    const nextBarSceneElement: HTMLElement | null = window.document.querySelector(
      `#${sceneList[currentIndex - 1]}`
    ) as HTMLElement;
    const screenBarWrapper = window.document.querySelector('.screen-bar__bar-wrapper') as HTMLElement;

    if (!nextBarSceneElement) {
      return;
    }
    const parentRect = screenBarWrapper.getBoundingClientRect();
    const buttonRect = nextBarSceneElement.getBoundingClientRect();

    gsap.timeline().to(screenBarWrapper, {
      duration: 1,
      scale: 1,
      x: window.innerWidth / 2 - (buttonRect.left / 2 + buttonRect.width / 4) + parentRect.left / 2,
      ease: 'power1.inOut',
      onComplete: () => {
        const nextParentRect = screenBarWrapper.getBoundingClientRect();
        const nextButtonRect = nextBarSceneElement.getBoundingClientRect();
        gsap.to(screenBarWrapper, {
          duration: 1,
          scale: 2,
          x: window.innerWidth / 2 - (nextButtonRect.left * 2 + nextButtonRect.width) + nextParentRect.left * 2,
          ease: 'power1.inOut',
          onComplete: () => {
            this.storeDispatch(changeBarScene(sceneList[currentIndex - 1] as EBarScene));
          },
        });
      },
    });
  }

  animateToNextScene(): void {
    const state: IReduxState = store.getState();
    const barScene: EScene = this.getBarScene();
    const sceneList: Array<string> = Object.keys(state.consumption === EConsumption.VAPER ? EBarSceneVape : EBarScene);
    const currentIndex: number = sceneList.indexOf(barScene);
    const nextBarSceneElement: HTMLElement | null = window.document.querySelector(
      `#${sceneList[currentIndex + 1]}`
    ) as HTMLElement;
    const screenBarWrapper = window.document.querySelector('.screen-bar__bar-wrapper') as HTMLElement;

    if (!nextBarSceneElement) {
      return;
    }
    const parentRect = screenBarWrapper.getBoundingClientRect();
    const buttonRect = nextBarSceneElement.getBoundingClientRect();

    if (this.firstTimeMoving) {
      this.firstTimeMoving = false;
      gsap.to(screenBarWrapper, {
        duration: 1,
        scale: 2,
        x:
          window.innerWidth > 767
            ? -(window.innerWidth / 2 - (buttonRect.left + buttonRect.width / 2))
            : window.innerWidth / 2 - buttonRect.left - buttonRect.width * 1.75,
        ease: 'power1.inOut',
        onComplete: () => {
          this.storeDispatch(changeBarScene(sceneList[currentIndex + 1] as EBarScene));
        },
      });
    } else {
      gsap.timeline().to(screenBarWrapper, {
        duration: 1,
        scale: 1,
        x: window.innerWidth / 2 - (buttonRect.left / 2 + buttonRect.width / 4) + parentRect.left / 2,
        ease: 'power1.inOut',
        onComplete: () => {
          const nextParentRect = screenBarWrapper.getBoundingClientRect();
          const nextButtonRect = nextBarSceneElement.getBoundingClientRect();
          gsap.to(screenBarWrapper, {
            duration: 1,
            scale: 2,
            x: window.innerWidth / 2 - (nextButtonRect.left * 2 + nextButtonRect.width) + nextParentRect.left * 2,
            ease: 'power1.inOut',
            onComplete: () => {
              this.storeDispatch(changeBarScene(sceneList[currentIndex + 1] as EBarScene));
            },
          });
        },
      });
    }
  }

  animateToCurrentScene(goToNextScene: boolean = false): void {
    const screenBarWrapper = window.document.querySelector('.screen-bar__bar-wrapper') as HTMLElement;
    const currentBarSceneElement: HTMLElement | null = window.document.querySelector(
      '.screen-bar__bar-wrapper .--is-active'
    ) as HTMLElement;

    if (!currentBarSceneElement) {
      setTimeout(() => {
        this.animateToCurrentScene(goToNextScene);
      }, 100);
      return;
    }

    this.firstTimeMoving = false;
    const nextParentRect = screenBarWrapper.getBoundingClientRect();
    const nextButtonRect = currentBarSceneElement.getBoundingClientRect();
    gsap.to(screenBarWrapper, {
      duration: 0,
      scale: 2,
      x: window.innerWidth / 2 - (nextButtonRect.left * 2 + nextButtonRect.width) + nextParentRect.left * 2,
      ease: 'power1.inOut',
      onComplete: () => {
        if (goToNextScene) {
          const goToNextSceneEvent = new Event('goToNextScene');
          window.dispatchEvent(goToNextSceneEvent);
          // this.animateToNextScene();
        }
      },
    });
  }

  animateToScene(scene: EScene, duration = 1): void {
    const state: IReduxState = store.getState();
    const sceneList: Array<string> = Object.keys(state.consumption === EConsumption.VAPER ? EBarSceneVape : EBarScene);
    const currentIndex: number = sceneList.indexOf(scene);
    const screenBarWrapper = window.document.querySelector('.screen-bar__bar-wrapper') as HTMLElement;
    const nextBarSceneElement: HTMLElement | null = window.document.querySelector(`#${scene}`) as HTMLElement;
    this.firstTimeMoving = false;
    const parentRect = screenBarWrapper.getBoundingClientRect();
    const buttonRect = nextBarSceneElement.getBoundingClientRect();
    gsap.timeline().to(screenBarWrapper, {
      duration,
      scale: 1,
      x: window.innerWidth / 2 - (buttonRect.left / 2 + buttonRect.width / 4) + parentRect.left / 2,
      ease: 'power1.inOut',
      onComplete: () => {
        const nextParentRect = screenBarWrapper.getBoundingClientRect();
        const nextButtonRect = nextBarSceneElement.getBoundingClientRect();
        gsap.to(screenBarWrapper, {
          duration,
          scale: 2,
          x: window.innerWidth / 2 - (nextButtonRect.left * 2 + nextButtonRect.width) + nextParentRect.left * 2,
          ease: 'power1.inOut',
          onComplete: () => {
            this.storeDispatch(changeBarScene(sceneList[currentIndex] as EBarScene));
          },
        });
      },
    });
  }

  /**
   * goToPreviousSmokeSceneNotComplete
   * Change the state of the `currentBarScreen` variable from the redux store
   * Display the previous screen
   */
  goToPreviousSmokeSceneNotComplete(): void {
    const barScene: EScene = this.getBarScene();
    const sceneList: Array<string> = Object.keys(EBarScene);
    const currentIndex: number = sceneList.indexOf(barScene);
    this.storeDispatch(changeBarScene(sceneList[currentIndex - 1] as EBarScene));
  }

  /**
   * goToRestartProfile
   * Restart the profile with the opposite of the current profile
   */
  goToRestartProfile(): void {
    const state: IReduxState = store.getState();
    this.storeDispatch(completeProfile(state.consumption));
    if (state.consumption === EConsumption.VAPER) {
      userInteraction.resetProfileSmoke();
    } else {
      userInteraction.resetProfileVape();
    }
    this.goToBarScreen(false);
  }

  /**
   * getBarScene
   * Get the current bar scene name
   * @return EBarScene
   */
  getBarScene(): EScene {
    const state: IReduxState = store.getState();
    return state.currentBarScene;
  }

  /**
   * getCompletedScene
   * Get the list of the completed activity
   * @return EScene[]
   */
  getCompletedScene(): EScene[] {
    const state: IReduxState = store.getState();
    return state.completedBarScene;
  }

  /**
   * goToMusic
   * Change the music base on the current scene
   * @param music: EMusicScene - Name of the enum for the music.
   * @return void
   */
  goToMusic(music: EMusicScene): void {
    this.storeDispatch(changeMusic(music));
  }

  /**
   * toggleMusicPlaying
   * Change the state of the music.
   * @param hasSoundEnabled: boolean - Can play music.
   * @return void
   */
  toggleMusicPlaying(hasSoundEnabled: boolean): void {
    if (!hasSoundEnabled) {
      googleTagManager.onSoundOff();
    } else {
      googleTagManager.onSoundStart();
    }
    this.storeDispatch(changeMusicState(hasSoundEnabled));
  }

  /**
   * isMusicEnabled
   * Get if the sound is currently on.
   * @return boolean
   */
  isMusicEnabled(): boolean {
    const state: IReduxState = store.getState();
    return state.isMusicPlaying;
  }
}

export default new SceneInteraction();
