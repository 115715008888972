import React, { MouseEventHandler, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';

import { EButtonColor, EButtonSize, EButtonType } from 'enum/button';
import './Button.scss';

interface IButtonProps {
  label?: string;
  link?: string;
  size?: EButtonSize;
  iconClassName?: string;
  className?: string;
  isDisabled?: boolean;
  isStyled?: boolean;
  icon?: any;
  debounceTime?: number;
  type?: EButtonType;
  backgroundColor?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
}
/**
 * Component that will show an button or a link, base on the link props.
 * @param label: string - Text to display inside the button
 * @param link: string - A link for the button to appear. Will display the button as <a> instead
 * @param size: EButtonSize - Size of the button
 * @param iconClassName: string - Add classname to the font-awesome component
 * @param className: string - Add others classname to the component
 * @param isDisabled: boolean - Disabled the button visually and add the html attribute
 * @param debounceTime: number - Time in MS for the debounce to act to avoid double click on item to skip steps.
 * @param debounceTime: EButtonType - The type of the button, "button" by default.
 * @param backgroundColor: EButtonColor - Add others color to the button
 * @param onClick: MouseEventHandler<HTMLButtonElement> - Add onClick event
 * @param isStyled: boolean - Display the styled button or the classic html one.
 * @param icon: [IconPrefix, IconName] - Display the font-awesome icon.
 * @param children: React.ReactNode - The children of the component
 * @returns React.ReactElement
 */

export const Button = ({
  label = '',
  link = '',
  size = EButtonSize.SMALL,
  className = '',
  iconClassName = '',
  isDisabled = false,
  isStyled = false,
  icon,
  debounceTime = 250,
  type = EButtonType.BUTTON,
  backgroundColor = EButtonColor.YELLOW,
  onClick = () => {},
  children,
}: IButtonProps): React.ReactElement => {
  const debounceCallback: (Event: React.MouseEvent) => void = useCallback(
    debounce(() => {
      onClick(Event as any);
    }, debounceTime),
    [onClick]
  );
  const styledButtonClassName = [
    'button',
    '--is-styled',
    size,
    className,
    isDisabled && '--is-disabled',
    backgroundColor,
  ];
  const unstyledButtonClassName = ['button', size, className, isDisabled && '--is-disabled'];
  const buttonContent: React.ReactChild = (
    <>
      {label && label}
      {icon && <FontAwesomeIcon className={`${iconClassName} ${!!label && '--icon-has-label'}`} icon={icon} />}
      {children && children}
    </>
  );

  if (link) {
    return (
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        onClick={(event) => debounceCallback(event)}
        className={isStyled ? styledButtonClassName.join(' ') : unstyledButtonClassName.join(' ')}
      >
        {buttonContent}
      </a>
    );
  }

  return (
    <button
      type={type}
      className={isStyled ? styledButtonClassName.join(' ') : unstyledButtonClassName.join(' ')}
      disabled={isDisabled}
      onClick={(event) => debounceCallback(event)}
    >
      {buttonContent}
    </button>
  );
};

export default Button;
