// import googleTagManager from './google-tag-manager';
import sceneInteraction from 'services/scene-interaction';
import userInteraction from 'services/user-interaction';
import {
  BarCocktailCoulisCeramiqueSVG,
  BarCocktailFormalehydeSVG,
  BarCocktailIngredientBatterieSVG,
  BarCocktailIngredientBoulesMitesSVG,
  BarCocktailIngredientDetergentToiletteSVG,
  BarCocktailIngredientDissolvantOnglesSVG,
  BarCocktailIngredientGoudronSVG,
  BarCocktailIngredientLaveGlaceSVG,
  BarCocktailIngredientMercureSVG,
  BarCocktailIngredientPesticideSVG,
  BarCocktailIngredientTolueneSVG,
  BarCocktailNickelSVG,
  BarCocktailPlombSVG,
  CocktailLaVeuveNoireSVG,
  CocktailLeRainbowShotSVG,
  CocktailLeSmokeyEyeSVG,
  CocktailMenthEurSVG,
  CocktailNameLaVeuveNoireSVG,
  CocktailNameLeRainbowShotSVG,
  CocktailNameLeSmokeyEyeSVG,
  CocktailNameMenthEurSVG,
  CocktailNameNicotineOnTheRockSVG,
  CocktailNameZombieSVG,
  CocktailNicotineOnTheRockSVG,
  CocktailZombieSVG,
} from 'services/exports-list';
import { ECocktaiIngredientlList } from 'enum/cocktail';
import { selectIngredient } from 'action/cocktail-action';
import IngredientJSON from 'component/activity-cocktail/ingredient.json';
import { ICocktailSelection } from 'interface/i-cocktail-selection';
import { EConsumption } from 'enum/user';

class CocktailInteraction {
  constructor() {
    this.setSelectedIngredient = this.setSelectedIngredient.bind(this);
    this.getCocktailByName = this.getCocktailByName.bind(this);
  }

  /**
   * getCocktailByName
   * Get the image of the cocktail by is enum name
   * @params name: ECocktaiIngredientlList - The enum name of the cocktail
   * @return: string - the SVG export of the image
   */
  getCocktailByName(name: ECocktaiIngredientlList): string {
    switch (name) {
      case ECocktaiIngredientlList.COULIS_CERAMIQUE:
        return BarCocktailCoulisCeramiqueSVG;
      case ECocktaiIngredientlList.FORMALEHYDE:
        return BarCocktailFormalehydeSVG;
      case ECocktaiIngredientlList.INGREDIENT_BATTERIE:
        return BarCocktailIngredientBatterieSVG;
      case ECocktaiIngredientlList.INGREDIENT_BOULESMITES:
        return BarCocktailIngredientBoulesMitesSVG;
      case ECocktaiIngredientlList.INGREDIENT_DETERGENT_TOILETTE:
        return BarCocktailIngredientDetergentToiletteSVG;
      case ECocktaiIngredientlList.INGREDIENT_DISSOLVANT_ONGLES:
        return BarCocktailIngredientDissolvantOnglesSVG;
      case ECocktaiIngredientlList.INGREDIENT_GOUDRON:
        return BarCocktailIngredientGoudronSVG;
      case ECocktaiIngredientlList.INGREDIENT_LAVE_GLACE:
        return BarCocktailIngredientLaveGlaceSVG;
      case ECocktaiIngredientlList.INGREDIENT_MERCURE:
        return BarCocktailIngredientMercureSVG;
      case ECocktaiIngredientlList.INGREDIENT_PESTICIDE:
        return BarCocktailIngredientPesticideSVG;
      case ECocktaiIngredientlList.NICKEL:
        return BarCocktailNickelSVG;
      case ECocktaiIngredientlList.PLOMB:
        return BarCocktailPlombSVG;
      case ECocktaiIngredientlList.INGREDIENT_TOLUENE:
        return BarCocktailIngredientTolueneSVG;
      default:
        return BarCocktailCoulisCeramiqueSVG;
    }
  }

  /**
   * Get a cocktail base on the first ingredient you choose.
   * @param ingredient : ECocktaiIngredientlList- The first ingredient of the list
   */
  getCocktailByIngredient(ingredient: ECocktaiIngredientlList): ICocktailSelection {
    // googleTagManager.onFinishCocktail();
    const consumptionType: EConsumption = userInteraction.getUserConsumption();
    if (consumptionType !== EConsumption.VAPER) {
      switch (ingredient) {
        case ECocktaiIngredientlList.INGREDIENT_BATTERIE:
        case ECocktaiIngredientlList.INGREDIENT_PESTICIDE:
          return {
            title: CocktailNameLeSmokeyEyeSVG,
            image: CocktailLeSmokeyEyeSVG,
            description:
              'Sens-tu la fumée? Ce shot est saturé de produits cancérigènes pour un shoot de nicotine rapide comme l’éclair. Un vrai régal pour les amateurs de sensations fortes!',
          };

        case ECocktaiIngredientlList.INGREDIENT_TOLUENE:
        case ECocktaiIngredientlList.INGREDIENT_MERCURE:
        case ECocktaiIngredientlList.INGREDIENT_GOUDRON:
          return {
            title: CocktailNameLaVeuveNoireSVG,
            image: CocktailLaVeuveNoireSVG,
            description:
              "Ce cocktail velouté contient tout ce qu'il faut pour doucement encrasser ton système circulatoire et respiratoire. Irrésistible et fatalement séduisant!",
          };
        default:
          return {
            title: CocktailNameNicotineOnTheRockSVG,
            image: CocktailNicotineOnTheRockSVG,
            description:
              'Ce cocktail frais et savoureux améliore l’absorption de la nicotine, rendant chaque gorgée délicieuse; une étape de plus vers la dépendance. À consommer sans modération!',
          };
      }
    }

    switch (ingredient) {
      case ECocktaiIngredientlList.INGREDIENT_LAVE_GLACE:
      case ECocktaiIngredientlList.INGREDIENT_PESTICIDE:
        return {
          title: CocktailNameLeRainbowShotSVG,
          image: CocktailLeRainbowShotSVG,
          description:
            'Une multitude de saveurs offertes avec notre toute nouvelle recette! On connaît peu les effets à long terme de sa consommation; ça sera à toi de nous le dire! Mais avec notre ingrédient secret, une dose de nicotine dans chaque verre, fidélité garantie!',
        };

      case ECocktaiIngredientlList.FORMALEHYDE:
      case ECocktaiIngredientlList.NICKEL:
        return {
          title: CocktailNameZombieSVG,
          image: CocktailZombieSVG,
          description:
            'Une exclusivité du Nico-Bar! Un goût tropical et fruité qui te fera oublier ses ingrédients toxiques. Attention, sa consommation peut provoquer une modification de l’ADN. Walking Dead saison 32, bientôt dans tes drinks!',
        };
      default:
        return {
          title: CocktailNameMenthEurSVG,
          image: CocktailMenthEurSVG,
          description:
            'Qu’est-ce qui te garantit un moment de bonheur délicieux, mentholé et relaxant? C’est le Menth-eur! Un mocktail envoûtant qui finira par te procurer du stress, de l’anxiété et des troubles du sommeil. Notre meilleur vendeur!',
        };
    }
  }

  getIngredientListByConsumption(consumptionType: EConsumption): Array<ECocktaiIngredientlList> {
    if (consumptionType === EConsumption.VAPER) {
      return [
        ECocktaiIngredientlList.PLOMB,
        ECocktaiIngredientlList.INGREDIENT_LAVE_GLACE,
        ECocktaiIngredientlList.FORMALEHYDE,
        ECocktaiIngredientlList.NICKEL,
        ECocktaiIngredientlList.COULIS_CERAMIQUE,
        ECocktaiIngredientlList.INGREDIENT_PESTICIDE,
      ];
    }
    return [
      ECocktaiIngredientlList.INGREDIENT_BATTERIE,
      ECocktaiIngredientlList.INGREDIENT_MERCURE,
      ECocktaiIngredientlList.INGREDIENT_GOUDRON,
      ECocktaiIngredientlList.INGREDIENT_DETERGENT_TOILETTE,
      ECocktaiIngredientlList.INGREDIENT_BOULESMITES,
      ECocktaiIngredientlList.INGREDIENT_DISSOLVANT_ONGLES,
      ECocktaiIngredientlList.INGREDIENT_TOLUENE,
      ECocktaiIngredientlList.INGREDIENT_PESTICIDE,
    ];
  }

  /**
   * Get a text to describe where to find this ingredient.
   * @param ingredient : ECocktaiIngredientlList- The first ingredient of the list
   * @return string - The description of the ingredient
   */
  getIngredientWhereToFind(ingredient: ECocktaiIngredientlList | null): string {
    if (ingredient && IngredientJSON.whereToFind[ingredient]) {
      return IngredientJSON.whereToFind[ingredient];
    }
    return 'Glisse sur les chiffres 3 ingrédients exclusifs parmi la sélection du Nico-Bar pour que Nico prépare ton mocktail.';
  }

  /**
   * Get a warning about the ingredient.
   * @param ingredient : ECocktaiIngredientlList- The first ingredient of the list
   * @return string - The description of the ingredient
   */
  getIngredientWarning(ingredient: ECocktaiIngredientlList | null): string {
    if (ingredient && IngredientJSON.warning[ingredient]) {
      return IngredientJSON.warning[ingredient];
    }
    return '';
  }

  setSelectedIngredient(ingredient: ECocktaiIngredientlList | null, index: number): void {
    sceneInteraction.storeDispatch(selectIngredient(ingredient, index));
  }
}

export default new CocktailInteraction();
