import React from 'react';
import SplashScreenPresential from 'presential/splash-screen/SplashScreenPresential';
import './ScreenStart.scss';

const ScreenStart = (): React.ReactElement => {
  return (
    <div className="screen-start">
      <div className="screen-start__background-sky" />
      <SplashScreenPresential />
    </div>
  );
};
export default ScreenStart;
