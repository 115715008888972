import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import VisageSDKMirror from '../../component/visagesdk-mirror/VisageSDKMirror';
import DeepARMirror from '../../component/deepar-mirror/DeepARMirror';
import SVGLoader from 'component/svg/SVG';
import { EConsumption } from 'enum/user';
import { MiroirSVG } from 'services/exports-list';
import userInteraction from 'services/user-interaction';

import './ActivityMirror.scss';

interface IProps {
  isAfterMirror?: boolean;
}

const ActivityMirror = ({ isAfterMirror = false }: IProps): React.ReactElement => {
  const userConsumptionType: EConsumption = userInteraction.getUserConsumption();
  const currentIndex = userConsumptionType === EConsumption.VAPER ? 2 : 1;

  return (
    <div className="activity-mirror">
      <div className="activity-mirror__mirror">
        <SVGLoader src={MiroirSVG} className="activity-mirror__mirror-img" />
        <div className="activity-mirror__mirror-content">
          <SwitchTransition>
            <CSSTransition
              key={currentIndex}
              addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
              classNames="fade"
            >
              <>
                {currentIndex < 2 && <VisageSDKMirror />}
                {currentIndex >= 2 && <DeepARMirror isUnmount={isAfterMirror} />}
              </>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </div>
  );
};
export default ActivityMirror;
