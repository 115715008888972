import React from 'react';
import Button from 'component/button/Button';
// import SVGLoader from 'component/svg/SVG';
import sceneInteraction from 'services/scene-interaction';
import './SplashScreenPresential.scss';
import { EButtonColor, EButtonSize } from 'enum/button';
import userInteraction from 'services/user-interaction';
import { EScene } from 'enum/scene';
import { EConsumption } from 'enum/user';

const SplashScreenPresential = (): React.ReactElement => {
  function setConsumptionAndMove(consumption: EConsumption) {
    userInteraction.setConsumption(consumption);
    sceneInteraction.goToScreen(EScene.MIRROR);
  }

  const urlParams = new URLSearchParams(window.location.search);
  const type: EConsumption = urlParams.has('type') ? (urlParams.get('type') as EConsumption) : EConsumption.NOT_CHOSEN;

  return (
    <div className="splash-screen-presential">
      {!type && (
        <>
          <div className="mt-4 t-center">
            <Button
              isStyled
              size={EButtonSize.BIG}
              onClick={() => setConsumptionAndMove(EConsumption.SMOKER)}
              label="Fumer"
            />
          </div>
          <div className="mt-4 t-center">
            <Button
              isStyled
              size={EButtonSize.BIG}
              onClick={() => setConsumptionAndMove(EConsumption.VAPER)}
              label="Vapoter"
            />
          </div>
        </>
      )}
      {(type === EConsumption.SMOKER || type === EConsumption.VAPER) && (
        <Button
          isStyled
          size={EButtonSize.BIG}
          backgroundColor={EButtonColor.RED}
          onClick={() => setConsumptionAndMove(type)}
          label="Viens voir les effets du Nico-Bar"
        />
      )}
    </div>
  );
};
export default SplashScreenPresential;
