import React, { useEffect } from 'react';

import './VisageSDKMirror.scss';

declare global {
    interface Window {
        VISAGE_SDK_LICENSE: string;
        initFilter: () => void;
        StopCamera: () => void;
        StopTracker: () => void;
        rAF: any;
    }
}

interface IProps {
    isUnmount?: boolean;
}

const VisageSDKMirror = ({ isUnmount = false }: IProps) => {
    const license = process.env.REACT_APP_VISAGE_SDK_LICENSE;

    useEffect(() => {
        if (isUnmount) {
            if(window.StopTracker) window.StopTracker();
            if(window.StopCamera) window.StopCamera();

            const scriptSrc = 'visageSDK/visageSDK.js';
            const existingScript = document.querySelector(`script[src="${scriptSrc}"]`);
            if (existingScript) {
                document.body.removeChild(existingScript);
            }

            setTimeout(() => {
                if(window.rAF) cancelAnimationFrame(window.rAF);
            },0);
        }
    }, [isUnmount]);

    useEffect(() => {
        if(license){
            window.VISAGE_SDK_LICENSE = license;

            if(window.initFilter) window.initFilter();

            const scriptSrc = 'visageSDK/visageSDK.js';
            const existingScript = document.querySelector(`script[src="${scriptSrc}"]`);
            if (!existingScript) {
                const script = document.createElement('script');
                script.src = 'visageSDK/visageSDK.js';
                script.type= 'module';
                document.body.appendChild(script);
            }

            return () => {
                if(window.StopTracker) window.StopTracker();
                if(window.StopCamera) window.StopCamera();

                const scriptSrc = 'visageSDK/visageSDK.js';
                const existingScript = document.querySelector(`script[src="${scriptSrc}"]`);
                if (existingScript) {
                    document.body.removeChild(existingScript);
                }
            };
        }
    }, []);


    return (
        <div id="cinema">
            <div id="outer-container">
                <div id="inner-container">
                    <canvas id="canvas" />
                    <canvas id="bgcanvas" style={{ display: 'none' }}/>
                    <video className="video" id="webcam" autoPlay playsInline/>
                </div>
            </div>
        </div>
    );
};

export default VisageSDKMirror;
